<template>
  <navbar></navbar>
  <prod></prod>
  <foot></foot>
</template>

<script>
import navbar from "@/components/nav/navbar.vue";
import foot from "@/components/footer/foot.vue";
import prod from "@/components/song/prod.vue";

export default {
  name : 'song',
  components : {
    navbar,
    foot,
    prod,
  },
}
</script>

<style scoped>

</style>
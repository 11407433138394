import { createRouter, createWebHistory } from 'vue-router'
import Indexvue from '../views/Index.vue'
import Price from "@/views/Price.vue";
import Song from "@/views/Song.vue";

const routes = [
  {
    path: '/',
    name: 'indexvue',
    component: Indexvue
  },
  {
    path: '/tarification',
    name: 'price',
    component: Price
  },
  {
    path: '/creations',
    name: 'song',
    component: Song
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <navbar></navbar>
  <index></index>
  <foot></foot>
</template>

<script>
import navbar from "@/components/nav/navbar.vue";
import index from "@/components/index/index.vue";
import foot from "@/components/footer/foot.vue";
export default {
  name: 'indexvue',
  components: {
    navbar,
    index,
    foot,
  },
}
</script>

<style scoped>
</style>

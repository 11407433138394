<template>
  <navbar></navbar>
  <tarif></tarif>
  <foot></foot>
</template>

<script>
  import navbar from "@/components/nav/navbar.vue";
  import foot from "@/components/footer/foot.vue";
  import tarif from "@/components/price/tarif.vue";

  export default {
    name : 'price',
    components : {
      navbar,
      foot,
      tarif
    },
  }
</script>

<style scoped>

</style>
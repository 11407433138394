<template>
  <nav>
    <div class="nav" :style="{ display: navDisplay }">
      <img src="../img/logo/logo-text.png" alt="">
      <div class="icons">
        <span><p class="circle">0</p><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 9C5 7.89543 5.89543 7 7 7H17C18.1046 7 19 7.89543 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 10V6C15 4.34315 13.6569 3 12 3V3C10.3431 3 9 4.34315 9 6V10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></span>
        <a href="" class="account">Mon Espace</a>
      </div>
    </div>
    <div class="links">
      <a href="/">Accueil</a>
      <a href="#">Agenda</a>
      <a href="/creations">Prods</a>
      <a href="/tarification">Boutique</a>
      <a href="#">Contact</a>
    </div>
  </nav>

  <div class="nav-mobile">
    <img src="../img/logo/logo.png" alt="">
    <div class="icons">
      <span><p class="circle">0</p><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 9C5 7.89543 5.89543 7 7 7H17C18.1046 7 19 7.89543 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 10V6C15 4.34315 13.6569 3 12 3V3C10.3431 3 9 4.34315 9 6V10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></span>
      <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  components: {},
  data() {
    return {
      navDisplay: 'flex'
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 50) {
        this.navDisplay = 'none';
      } else {
        this.navDisplay = 'flex';
      }
    }
  }
}
</script>

<style scoped>
  .nav-mobile{
    display: none;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 100%;
    background: #FFFFFF;
    padding: 4px 24px;
    position: fixed;
    top: 0;
    z-index: 2;
    align-items: center;
    justify-content: space-between;
  }

  .nav-mobile img{
    display: flex;
    width: 100%;
    max-width: 70px;
    object-fit: cover;
  }

  nav{
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    padding: 0 24px 0 24px;
    background-color: #FFFFFF;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    z-index: 2;
  }

  .nav{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60px;
  }

  .nav img{
    display: flex;
    width: 100px;
    object-fit: cover;
  }

  .links{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    height: 50px;
  }

  .links a{
    display: flex;
    min-width: 150px;
    justify-content: center;
    border-bottom: solid 3px white;
    height: 50px;
    align-items: center;
  }

  .links a:hover{
    border-bottom: solid 3px #000000;
    color: #000000;
    font-weight: bold;
  }

  .icons{
    display: flex;
    position: absolute;
    right: 0;
    grid-gap: 12px;
  }

  .icons span{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .circle{
    background-color: red;
    width: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 16px;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50px;
    font-size: 10px;
  }

  .account{
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background-color: black;
    padding: 0 24px;
    border-radius: 50px;
  }

  @media (max-width: 1100px) {
    nav{
      display: none;
    }

    .nav-mobile{
      display: flex;
    }

    .icons{
      margin-right: 24px;
    }
  }
</style>

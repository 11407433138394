<template>
  <div class="banner-album">
    <div class="title">
      <h1>LE SON PARFAIT</h1>
      <div class="input">
        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        <input type="text" name="search" id="search" placeholder="Recherche un titre ou des artists">
      </div>
        <a href="" class="cta"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#000000"></path> </g></svg>J'écoute</a>
    </div>
    <img src="../img/wallpapers/wall_1.png" alt="">
  </div>
  <section>
    <div class="banner filter">
      <div class="title">
        <h1>TENDANCE</h1>
        <div class="category">
          <span><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.8324 21.8013C15.9583 21.1747 20 18.926 20 13.1112C20 7.8196 16.1267 4.29593 13.3415 2.67685C12.7235 2.31757 12 2.79006 12 3.50492V5.3334C12 6.77526 11.3938 9.40711 9.70932 10.5018C8.84932 11.0607 7.92052 10.2242 7.816 9.20388L7.73017 8.36604C7.6304 7.39203 6.63841 6.80075 5.85996 7.3946C4.46147 8.46144 3 10.3296 3 13.1112C3 20.2223 8.28889 22.0001 10.9333 22.0001C11.0871 22.0001 11.2488 21.9955 11.4171 21.9858C10.1113 21.8742 8 21.064 8 18.4442C8 16.3949 9.49507 15.0085 10.631 14.3346C10.9365 14.1533 11.2941 14.3887 11.2941 14.7439V15.3331C11.2941 15.784 11.4685 16.4889 11.8836 16.9714C12.3534 17.5174 13.0429 16.9454 13.0985 16.2273C13.1161 16.0008 13.3439 15.8564 13.5401 15.9711C14.1814 16.3459 15 17.1465 15 18.4442C15 20.4922 13.871 21.4343 12.8324 21.8013Z" fill="#FF8000FF"></path> </g></svg>TYPE GAZO</span>
          <span><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.8324 21.8013C15.9583 21.1747 20 18.926 20 13.1112C20 7.8196 16.1267 4.29593 13.3415 2.67685C12.7235 2.31757 12 2.79006 12 3.50492V5.3334C12 6.77526 11.3938 9.40711 9.70932 10.5018C8.84932 11.0607 7.92052 10.2242 7.816 9.20388L7.73017 8.36604C7.6304 7.39203 6.63841 6.80075 5.85996 7.3946C4.46147 8.46144 3 10.3296 3 13.1112C3 20.2223 8.28889 22.0001 10.9333 22.0001C11.0871 22.0001 11.2488 21.9955 11.4171 21.9858C10.1113 21.8742 8 21.064 8 18.4442C8 16.3949 9.49507 15.0085 10.631 14.3346C10.9365 14.1533 11.2941 14.3887 11.2941 14.7439V15.3331C11.2941 15.784 11.4685 16.4889 11.8836 16.9714C12.3534 17.5174 13.0429 16.9454 13.0985 16.2273C13.1161 16.0008 13.3439 15.8564 13.5401 15.9711C14.1814 16.3459 15 17.1465 15 18.4442C15 20.4922 13.871 21.4343 12.8324 21.8013Z" fill="#FF8000FF"></path> </g></svg>DRILL FR</span>
          <span><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.8324 21.8013C15.9583 21.1747 20 18.926 20 13.1112C20 7.8196 16.1267 4.29593 13.3415 2.67685C12.7235 2.31757 12 2.79006 12 3.50492V5.3334C12 6.77526 11.3938 9.40711 9.70932 10.5018C8.84932 11.0607 7.92052 10.2242 7.816 9.20388L7.73017 8.36604C7.6304 7.39203 6.63841 6.80075 5.85996 7.3946C4.46147 8.46144 3 10.3296 3 13.1112C3 20.2223 8.28889 22.0001 10.9333 22.0001C11.0871 22.0001 11.2488 21.9955 11.4171 21.9858C10.1113 21.8742 8 21.064 8 18.4442C8 16.3949 9.49507 15.0085 10.631 14.3346C10.9365 14.1533 11.2941 14.3887 11.2941 14.7439V15.3331C11.2941 15.784 11.4685 16.4889 11.8836 16.9714C12.3534 17.5174 13.0429 16.9454 13.0985 16.2273C13.1161 16.0008 13.3439 15.8564 13.5401 15.9711C14.1814 16.3459 15 17.1465 15 18.4442C15 20.4922 13.871 21.4343 12.8324 21.8013Z" fill="#FF8000FF"></path> </g></svg>RAP</span>
        </div>
      </div>
      <div class="sound">
        <a href="" class="embed">
          <img src="../img/albums/pochette.png">
          <h1>Infinity</h1>
          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#FFFFFF"></path> </g></svg>
        </a>
        <a href="" class="embed">
          <img src="../img/albums/pochette.png">
          <h1>Infinity</h1>
          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#FFFFFF"></path> </g></svg>
        </a>
        <a href="" class="embed">
          <img src="../img/albums/pochette.png">
          <h1>Infinity</h1>
          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#FFFFFF"></path> </g></svg>
        </a>
        <a href="" class="cta"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.8324 21.8013C15.9583 21.1747 20 18.926 20 13.1112C20 7.8196 16.1267 4.29593 13.3415 2.67685C12.7235 2.31757 12 2.79006 12 3.50492V5.3334C12 6.77526 11.3938 9.40711 9.70932 10.5018C8.84932 11.0607 7.92052 10.2242 7.816 9.20388L7.73017 8.36604C7.6304 7.39203 6.63841 6.80075 5.85996 7.3946C4.46147 8.46144 3 10.3296 3 13.1112C3 20.2223 8.28889 22.0001 10.9333 22.0001C11.0871 22.0001 11.2488 21.9955 11.4171 21.9858C10.1113 21.8742 8 21.064 8 18.4442C8 16.3949 9.49507 15.0085 10.631 14.3346C10.9365 14.1533 11.2941 14.3887 11.2941 14.7439V15.3331C11.2941 15.784 11.4685 16.4889 11.8836 16.9714C12.3534 17.5174 13.0429 16.9454 13.0985 16.2273C13.1161 16.0008 13.3439 15.8564 13.5401 15.9711C14.1814 16.3459 15 17.1465 15 18.4442C15 20.4922 13.871 21.4343 12.8324 21.8013Z" fill="#FF8000FF"></path> </g></svg>Encore plus</a>
      </div>
    </div>
  </section>
  <section>
    <div class="banner float">
      <h1>WAH RECORDS</h1>
      <span>Un studio indépendant réunit un ingénieur du son créatif spécialisé dans la conception artistique et la création d'univers musicaux distinctifs pour chaque artiste.</span>
      <a href="" class="cta"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 9.5V15.5M9 12.5H15M8.4 19C5.41766 19 3 16.6044 3 13.6493C3 11.2001 4.8 8.9375 7.5 8.5C8.34694 6.48637 10.3514 5 12.6893 5C15.684 5 18.1317 7.32251 18.3 10.25C19.8893 10.9449 21 12.6503 21 14.4969C21 16.9839 18.9853 19 16.5 19L8.4 19Z" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></a>
      <img class="background" src="../img/wallpapers/wall.png">
      <img class="background-mobile" src="../img/wallpapers/wall.png">
    </div>
  </section>
</template>

<script>
  export default {
    name: 'index',
    components:{
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        const banner = document.querySelector('.banner .background');
        if (banner) {
          const scrollPosition = window.scrollY;
          if (scrollPosition < 700) {
            banner.style.transform = `translateY(${scrollPosition * 0.5}px)`;
          }
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
</script>

<style scoped>
  section .filter{
    display: flex;
    background: #000000;
    width: 100%;
    opacity: 100%;
    padding: 64px 128px;
    flex-direction: column;
    grid-gap: 16px;
  }

  .filter .title{
    flex-direction: column;
    display: flex;
    grid-gap: 8px;
  }

  .filter .sound{
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 16px;
    align-items: center;
    margin-top: 50px;
  }

  .filter .sound .embed{
    display: flex;
    width: 100%;
    align-items: center;
    background: #101010;
    grid-gap: 8px;
    padding: 0 24px;
    height: 70px;
    color: #FFFFFF;
    border-radius: 5px;
    max-width: 900px;
    position: relative;
    grid-gap: 18px;
  }

  .filter .sound .embed:hover{
    transform: scale(1.05);
    box-shadow: rgb(255, 255, 255) 0px 1px 5px;
  }

  .filter .sound .embed svg{
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 24px;
  }

  .filter .sound .embed h1{
    color: white;
  }

  .filter .cta{
    display: flex;
    color: #000000;
    width: 250px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    font-size: 24px;
    background-color: #FFFFFF;
    padding: 8px 32px;
    font-weight: 600;
    border-radius: 50px;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 5px 15px;
    margin-top: 24px;
  }

  .filter .embed img{
    display: flex;
    width: 100%;
    max-width: 50px;
    position: relative;
    border-radius: 5px;
  }

  .filter .category{
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
  }

  .category span{
    display: flex;
    grid-gap: 4px;
    color: #FFFFFF;
    align-items: center;
    font-weight: 600;
    padding: 4px 12px;
    border: solid 4px #FFFFFF;
    border-radius: 50px;
  }

  section{
    display: flex;
    width: 100%;
  }

  section .float{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 128px 64px;
    grid-gap: 16px;
    position: relative;
  }

  section .float .background{
    display: flex;
    width: 100%;
    position: absolute;
    z-index: -100;
    filter: blur(3px);
    bottom: 0;
  }

  
  section .float .cta{
    display: flex;
    color: #FFFFFF;
    align-items: center;
    grid-gap: 8px;
    font-size: 24px;
    background-color: #000000;
    padding: 8px 32px;
    font-weight: 600;
    border-radius: 50px;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 5px 15px;
    margin-top: 24px;
  }

  section .float span{
    display: flex;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  section .float h1{
    display: flex;
    font-size: 64px;
    font-weight: 900;
    border: solid 5px black;
    padding: 0 64px;
    border-radius: 50px;
  }

  .banner-album{
    display: flex;
    width: 100%;
    background-color: black;
    margin-top: 111px;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 476px;
  }

  .banner-album img{
    display: flex;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
    top: 100px;
    animation: 15s infinite float-effect;
    height: 100%;
  }


  @keyframes float-effect {
    0%{
      opacity: 0;
      padding-left: 30px;
    }

    20%{
      opacity: .2;
      padding-left: 0;
    }

    90%{
      opacity: .2;
      padding-right: 0;
    }
    100%{
      opacity: 0;
      padding-right: 30px;
    }
  }

  .banner-album .title{
    position: absolute;
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
  }

  .title h1{
    color: #FFFFFF;
    font-size: 64px;
    font-weight: 700;
    text-shadow: 6px 6px 0px rgba(0,0,0,0.2);
  }

  .title .input{
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 50px;
    border: solid 3px #FFFFFF;
    box-shadow: rgba(255, 255, 255, 0.13) 0px 5px 15px;
    border-radius: 50px;
    width: 100%;
    max-width: 450px;
  }

  .title .cta{
    display: flex;
    color: black;
    align-items: center;
    grid-gap: 8px;
    font-size: 24px;
    background-color: #FFFFFF;
    padding: 8px 16px;
    font-weight: 600;
    border-radius: 50px;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 5px 15px;
  }


  .input input{
    display: flex;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: #FFFFFF;
    height: 100%;
    padding: 0 8px;
  }

  .background-mobile{
    display: none;
  }

  @media (max-width: 800px) {
    .banner-album{
      margin-top: 78px;
    }

    .banner-album img{
      display: flex;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .banner-album .cta{
      margin-top: 30px;
    }

    section .filter{
      padding: 128px 24px;
    }

    .title h1{
      font-size: 40px;
    }

    section .float h1{
      font-size: 40px;
      padding: 0 24px;
    }

    .filter .category{
      flex-wrap: wrap;
    }

    .category span{
      border: solid 3px white;
    }

    section .float{
      padding: 128px 24px;
    }

    section .float .background{
      display: none;
    }

    section .float .background-mobile{
      display: flex;
      width: 100%;
      position: absolute;
      z-index: -100;
      filter: blur(3px);
      height: 100%;
      object-fit: cover;
      top: 0;
    }
  }
</style>
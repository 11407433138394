<template>
<footer>
  <img src="../img/logo/logo-text.png" alt="">
  <div class="banner">
    <div class="box">
      <div class="title">
        <h4>WAH Records</h4>
      </div>
      <a href="">Notre histoire</a>
      <a href="">Nos créations</a>
      <a href="">Spotify</a>
    </div>
    <div class="box">
      <div class="title">
        <h4>Support</h4>
      </div>
      <a href="">Prix</a>
      <a href="">Se connecter</a>
      <a href="">S'inscrire</a>
      <a href="">Nous contacter</a>
      <a href="">Paramètres Cookie</a>
    </div>
    <div class="box">
      <div class="title">
        <h4>Réseaux sociaux</h4>
      </div>
      <a href="">Instagram</a>
      <a href="">TikTok</a>
      <a href="">Spotify</a>
    </div>
  </div>
  <hr class="bot">
  <a href="#"><small>Ce site est protégé par des droits d'auteur.</small></a>
  <a href="https://hbc-group.fr" class="watermark"><small>HBC GROUP</small></a>
</footer>
</template>

<script>
  export default {
    name : 'foot',
  }
</script>

<style scoped>
  footer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 16px 64px;
    grid-gap: 16px;
  }

  footer a small{
    font-weight: bold;
  }

  .watermark{
    font-size: 12px;
  }

  hr{
    border: solid 1px #000000;
    display: flex;
    width: 100%;
  }

  .bot{
    border: solid 1px #eeeeee;
    margin-top: 24px;

  }

  .banner{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    grid-gap: 200px;
  }

  .box{
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    min-height: 200px;
    min-width: 200px;
  }

  .box .title{
    display: flex;
  }

  .title h4{
    font-size: 18px;
    font-weight: 900;
  }

  footer img{
    display: flex;
    width: 128px;
    object-fit: cover;
  }

  @media (max-width: 1100px) {
    footer{
      padding: 16px 24px;
    }

    .banner{
      flex-direction: column;
      grid-gap: 0;
    }
  }
</style>
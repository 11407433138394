<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
  <router-view />
</template>
<script>
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Work Sans", sans-serif;
  box-sizing: border-box;
  color: #000000;
  font-weight: normal;
}

body{
  background-color: #F1F1F1F1;
}

#app{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>

<template>
  <section class="section">
  </section>
</template>

<script>
export default {
  name: 'prod',
}
</script>

<style scoped>
.section{
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  padding: 128px 128px 24px 128px;
  grid-gap: 64px;
  position: relative;
}


</style>